///////////////////////
/////Order Confirmation
///////////////////////
#wrapper.pt_order,
#wrapper.pt_order-confirmation {
	#main {
		.order-confirmation-details {
			table.order-shipment-table {
				.gc-to-email .value {
					text-transform: none;
				}
				.order-shipment-details {
					.egc-to-email {
						text-transform: none;
					}
				}
				tr {
					td {
						.order-value {
							display:inline-block;
						}
						&:nth-child(3) {
							padding: 14px 0 14px 30px;
						}
					}
				}
			}
		}
	}
}

#wrapper.pt_checkout #main #primary .form-row.label-inline.form-indent.privacy-checkbox label span {
	font-size: 14px;
	color: #4C4C4C;
}

.continue-btn {
	@include respond(largeUp) {
		display: flex;
	}

	.opt-in-address {
		margin-top: 20px;
		font-size: 12px;
		line-height: 20px;
		color: #4C4C4C;

		@include respond(small) {
			margin-bottom: 0;
		}
	}

	.opt-in-address__link {
		color: #4C4C4C;
		font-size: 12px;
		line-height: 20px;
		text-decoration: underline;

		@include respond(small) {
			font-size: 14px;
			line-height: 26px;
		}

		&:hover {
			text-decoration: none;
		}
	}
}

#secondary {
	.opt-in-address .opt-in-address__link {
		@include respond(small) {
			font-size: 14px;
			line-height: 26px;
		}
	}
}